import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Section from '../components/Section'


const NotFound = () => {
    return (
        <Layout>
        <Section 
            image={require('../images/background-404.jpg')}
            title="Page Not Found"
            text="You should probably head back to safety."
        />
        </Layout>
    )
}

export default NotFound
