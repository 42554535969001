import React from 'react'
import styled from 'styled-components'

const SectionGroup = styled.div`
background: url(${props => props.image});
background-repeat: no-repeat;
background-size: contain;
height: 50%;
width: 100%;
border-radius: 25px;
padding: 20px;
`

const SectionTitleGroup = styled.div`
color: white;
display: grid;
grid-template-rows: 200px auto;
/* margin: 0 40px; */
`

const SectionTitle = styled.h3`
font-size: 40px;
margin: 100px 60px;
text-shadow: 2px 2px 8px #000000;
`

const SectionText = styled.p`
margin: 0 40px;
text-shadow: 2px 2px 8px #000000;
`

const Section = (props) => {
    return (
        <SectionGroup image={props.image}>
            <SectionTitleGroup>
                <SectionTitle>{props.title} </SectionTitle>
                <SectionText>{props.text}</SectionText>
            </SectionTitleGroup>
        </SectionGroup>
    )
}

export default Section
